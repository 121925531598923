<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-tooltip text="Request Doctor for Clinic Association">
      <vs-button v-if="inTabEdit" @click="getClinics" id="assignDoctorToClinic"
        >Assign Clinic</vs-button
      >
      <vs-button
        v-else
        @click="getClinics"
        size="small"
        type="border"
        icon-pack="feather"
        icon="icon-send"
      ></vs-button>
    </vx-tooltip>
    <vs-popup title="Request for Association" :active.sync="popupActivo">
      <div v-if="showSelect">
        <vs-select
          placeholder="Select Clinic"
          label="Clinics"
          multiple
          autocomplete
          id="clinicsSelect"
          v-model="clinicIds"
          class="w-full m-2"
        >
          <vs-select-item
            :key="index"
            :value="item._id"
            :text="item.name"
            v-for="(item, index) in clinics"
          />
        </vs-select>

        <vs-button
          @click="assignClinic"
          color="primary"
          class="m-2"
          id="confirmRequest"
          type="border"
          >Request</vs-button
        >
      </div>

      <div v-else>
        <vs-alert color="warning">Further clinics are not available.</vs-alert>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "request-doctor",
  props: {
    doctorId: {
      type: String,
      default: null,
    },
    inTabEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupActivo: false,
      clinics: [],
      clinicIds: "",
      showSelect: true,
      organizationId: "",
    };
  },
  methods: {
    ...mapActions("franchise", ["sendRequest", "fetchAssignClinics"]),
    ...mapActions("organization", ["fetchOrganizationAllClinics"]),
    async assignClinic() {
      let self = this;

      if (this.clinicIds.length <= 0) {
        self.$vs.notify({
          title: "Validation Failed",
          text: "Please select clinics for request.",
          color: "danger",
        });
        return false;
      }

      this.sendRequest({ doctorId: this.doctorId, clinicIds: this.clinicIds })
        .then((res) => {
          self.$vs.notify({
            title: "Clinic Join Request",
            text: "Request Sent Successfully.",
            color: "success",
          });
          this.popupActivo = false;
          this.clinicIds = [];
        })
        .catch((err) => {
          self.$vs.notify({
            title: "Clinic Join Request",
            text: "Request Sent Failed.",
            color: "danger",
          });
        });
    },
    getClinics() {
      this.$vs.loading();
      let self = this;
      this.popupActivo = true;
      this.fetchOrganizationAllClinics(this.organizationId)
        .then((res) => {
          if (res.data.data.length <= 0) this.showSelect = false;
          else {
            this.clinics = res.data.data;
            this.showSelect = true;
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          self.$vs.notify({
            title: "Error",
            text: "Error Fetching data from server. Please reload the page and try again.",
            color: "danger",
          });
          this.popupActivo = false;
          this.$vs.loading.close();
        });
    },
  },
  created() {
    this.organizationId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
  }
};
</script>
