<template>
<div>
    <vs-card v-if="inTabEdit" class="w-full associatedClinics">
      <div slot="header" class="flex items-center justify-end py-2" v-if="canAssign">
        <request-doctor
          v-if="isDoctor"
          :inTabEdit="inTabEdit"
          :doctorId="userId"
        />
        <assign-nurse
          v-else
          @fetchList="getAssociatedClinic"
          :inTabEdit="inTabEdit"
          :nurseId="userId"
          :associatedIds="associatedIds"
          :assignedClinics="clinics"
        />
      </div>  
      <div id="div-with-loading" class="vs-con-loading__container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="clinics"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
          class="clinicTable"
        >
          <div slot="header" class="flex flex-wrap flex-grow">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              id="pageSizeSelect"
              v-model="dataTableParams.limit"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in limitOptions"
              />
            </vs-select>

            <!-- <vs-select
              placeholder="Select Owner"
              autocomplete
              v-model="dataTableParams.organizationId"
              class="ml-2"
            >
              <vs-select-item
                :key="index"
                :value="item._id"
                :text="item.name"
                v-for="(item, index) in organizations"
              />
            </vs-select> -->
          </div>

          <template slot="thead">
            <vs-th>S. No.</vs-th>
            <vs-th sort-key="clinicName">Clinic Name</vs-th>
            <vs-th v-if="userTypeToEdit === 'doctor'"
              >Clinic Rate for Doctor</vs-th
            >
            <vs-th
              v-if="
                check_has_permission('removeDoctorAssociatedClinics') &&
                userTypeToEdit === 'doctor'
              "
              size="25%"
              >Action</vs-th
            >

            <vs-th
              v-if="
                check_has_permission('removeNurseAssociatedClinics') &&
                userTypeToEdit === 'nurse'
              "
              size="25%"
              >Action</vs-th
            >
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td data-label="S. No." :data="indextr">{{ (indextr + 1) | capitalize }}</vs-td>
              <vs-td data-label="Clinic Name" :data="item">{{
                item.clinicName ? item.clinicName : item.firstName | capitalize
              }}</vs-td>
              <vs-td data-label="Rate." :data="item" v-if="userTypeToEdit === 'doctor'">{{
                item.rate || item.rate === 0 ? `AUD ${item.rate}` : "N/A"
              }}</vs-td>
              <vs-td
                data-label="Action"
                v-if="userTypeToEdit === 'doctor'"
                :data="item"
                style="display: flex"
              >
                <vx-tooltip text="Remove Association">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-trash-2"
                    class="m-1"
                    @click="removeClinic(item._id, userTypeToEdit)"
                  ></vs-button>
                </vx-tooltip>
              </vs-td>

              <vs-td
                v-if="userTypeToEdit === 'nurse'"
                :data="item"
                style="display: flex"
              >
                <vx-tooltip text="Remove Association">
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-trash-2"
                    class="m-1"
                    @click="removeClinic(item._id, userTypeToEdit)"
                  ></vs-button>
                </vx-tooltip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vs-card>
    <vs-card
      v-else
      id="data-list-list-view"
      class="data-list-container"
    >
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="clinics"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap flex-grow">
          <vs-select
            placeholder="10"
            vs-multiple
            autocomplete
            v-model="dataTableParams.limit"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in limitOptions"
            />
          </vs-select>

          <!-- <vs-select
            placeholder="Select Owner"
            autocomplete
            v-model="dataTableParams.organizationId"
            class="m-1"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.name"
              v-for="(item, index) in organizations"
            />
          </vs-select> -->
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">S. No.</vs-th>
          <vs-th sort-key="lastName">Clinic Name</vs-th>
          <vs-th sort-key="rate" v-if="userTypeToEdit === 'doctor'"
            >Clinic Rate for Doctor</vs-th
          >
          <vs-th
            v-if="
              check_has_permission('removeDoctorAssociatedClinics') &&
              userTypeToEdit === 'doctor'
            "
            size="25%"
            >Action</vs-th
          >

          <vs-th
            v-if="
              check_has_permission('removeNurseAssociatedClinics') &&
              userTypeToEdit === 'nurse'
            "
            size="25%"
            >Action</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
            <vs-td :data="indextr">{{ (indextr + 1) | capitalize }}</vs-td>
            <vs-td :data="item">{{
              item.clinicName ? item.clinicName : item.firstName | capitalize
            }}</vs-td>
            <vs-td :data="item" v-if="userTypeToEdit === 'doctor'">{{
              item.rate || item.rate === 0 ? `AUD ${item.rate}` : "N/A"
            }}</vs-td>
            <vs-td
              v-if="
                check_has_permission('removeDoctorAssociatedClinics') &&
                userTypeToEdit === 'doctor'
              "
              :data="item"
              style="display: flex"
            >
              <vx-tooltip text="Remove Association">
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-trash-2"
                  class="m-1"
                  @click="removeClinic(item._id, userTypeToEdit)"
                ></vs-button>
              </vx-tooltip>
            </vs-td>

            <vs-td
              v-if="
                check_has_permission('removeNurseAssociatedClinics') &&
                userTypeToEdit === 'nurse'
              "
              :data="item"
              style="display: flex"
            >
              <vx-tooltip text="Remove Association">
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-trash-2"
                  class="m-1"
                  @click="removeClinic(item._id, userTypeToEdit)"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vs-card>
</div>
</template>

<script>
import { mapActions } from "vuex";
import assignNurse from "../others/assignNurse.vue";
import requestDoctorAssociation from "../others/requestDoctorAssociation.vue";

export default {
  name: "associated-clinics",
  props: {
    userId: {
      type: String,
      default: null,
    },
    isDoctor: {
      type: Boolean,
      default: false,
    },
    userTypeToEdit: {
      type: String,
      default: "doctor",
    },
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    canAssign: {
      type: Boolean,
      default: true,
    },
    associatedIds: {
      type: Array,
    },
  },
  components: {
    "assign-nurse": assignNurse,
    "request-doctor": requestDoctorAssociation,
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: -1,
        page: 1,
        limit: 25,
        filter: "All",
        organizationId: "",
      },
      clinics: [],
      limitOptions: [5, 10, 25, 50, 100],
      // organizations: [],
    };
  },
  methods: {
    ...mapActions("clinic", [
      "fetchAssociatedClinics",
      "removeAssociatedClinics",
    ]),
    async getAssociatedClinic() {
      await this.fetchAssociatedClinics({
        ...this.dataTableParams,
        ...{ userId: this.userId },
      }).then((res) => {
        this.clinics = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.noDataText = "No Associated Clinics Available";
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAssociatedClinic();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getAssociatedClinic();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAssociatedClinic();
    },
    async removeClinic(clinicId, userTypeToEdit) {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      await this.removeAssociatedClinics({
        userId: this.userId,
        clinicId: clinicId,
        userType: userTypeToEdit,
      })
        .then((res) => {
          return this.getAssociatedClinic();
        })
        .then(() => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Success",
            text: "Removed clinic from user successfully !",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: "Something went wrong !",
            color: "danger",
          });
          if (err.data.title === "Permission Denied" && err.status === 403)
            this.$router.push("/admin/permission-denied");
        });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getAssociatedClinic();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getAssociatedClinic();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getAssociatedClinic();
      }
    },
  },
  created() {
    const role = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );
    if (role && role.toUpperCase() === "org owner".toUpperCase()) {
      const organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      this.dataTableParams.organizationId = organizationId;
    }
    this.getAssociatedClinic();
  },
};
</script>

<style>
  @media screen and (max-width: 485px) {
    .clinicTable .vs-table--header {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .clinicTable .vs-table--header > *:first-of-type {
      margin-right: 0.3rem;
      width: 23%;
    }
    .clinicTable .vs-table--header > *:last-of-type {
      margin-left: 0.3rem;
    }
    .clinicTable .vs-table--header > *,
    .con-select,
    .clinicTable .vs-table--search-input {
      width: 100%;
      max-width: 100%;
    }
  }

  /* Responsive <table> */
  @media screen and (max-width: 600px) {
    .clinicTable .vs-table--search {
      max-width: 100%;
      width: 100%;
    }
    .clinicTable .vs-table--tbody-table {
        min-width: unset;
    }
    .clinicTable .vs-table--thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none !important;
    }
    .clinicTable .vs-table--tr {
      display: block;
      margin-bottom: .625em;
    }
    .clinicTable .vs-table--td {
      border-bottom: 1px solid #eee;
      display: block;
      font-size: 1rem;
      text-align: right;
    }
    .clinicTable .vs-table--td:first-child{
      min-width: 230px;
    }
    .clinicTable .vs-table--td::before {
      content: attr(data-label);
      float: left;
      font-weight: 500;
      font-size: 13px;
    }
    .clinicTable .vs-table--td:last-child {
      border-bottom: 0;
    }
  }

  .associatedClinics .vs-card--header {
      padding: 5px;
  }
</style>
